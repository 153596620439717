import React from "react"
import { StaticQuery, graphql, navigate } from "gatsby"
import * as styles from "../../../styles/components/search-filter.module.scss"
import * as buttonStyles from "../../../styles/components/button.module.scss"

// markup
class SearchFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter1: props.filter1Init,
      filter2: props.filter2Init,
      filter3: props.filter3Init,
      current: props.current
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    let name = e.target.name
    if (name === 'filter1') {
      this.setState({
        [name]: e.target.value,
        filter2: 0,
        filter3: 'market-trend'
      })  
    } else {
      this.setState({[name]: e.target.value})
    }
  }

  render() {
    let filter1 = parseInt(this.state.filter1),
        filter2 = parseInt(this.state.filter2)
        
    return (
      <StaticQuery
        query={graphql`
          query SearchFilterQuery {
            filter: allChartPageFilterJson {
              edges {
                node {
                  id
                  nodes {
                    filter2
                    name
                    type
                    nodes {
                      filter3
                      name
                    }
                  }
                  filter1
                  name
                  type
                }
              }
            }
          }
        `}
        render={data => (
          <form className={styles.searchFilter} onSubmit={(e) => {
              e.preventDefault()
              const path1 = data.filter.edges.filter((item) => item.node.type === filter1)[0].node.nodes[this.state.filter2].filter2
              const path2 = this.state.filter3
              const link = `/dashboard/${path1}-${path2}/`

              if (link !== this.state.current) {
                navigate(path1 === 'co2concrete' && path2 === 'technology-trend' ? '/dashboard/co2concrete-market-trend/' : link)
              }
          }}>
            <div className={styles.selectArea}>
              <div className={styles.selectAreaItem}>
                <label htmlFor="filter1">重点分野</label>
                <div className={`${styles.inputSelect} ${styles.important}`}>
                  <select name="filter1" value={this.state.filter1} id="filter1" onChange={this.handleChange}>
                    { data.filter.edges.map((item, id ) => {
                      return <option key={id} value={item.node.type}>{item.node.name}</option>
                    }) }
                  </select>
                </div>
              </div>
              <div className={styles.selectAreaItem}>
                <label htmlFor="filter2">個別分野</label>
                <div className={`${styles.inputSelect} ${styles.individual}`}>
                  <select name="filter2" value={this.state.filter2} id="filter2" onChange={this.handleChange}>
                    { data.filter.edges.filter((item) => item.node.type === filter1)[0].node.nodes.map((item, id ) => {
                      return (item.filter2 === "geothermal") ? '' : <option key={id} value={item.type}>{item.name}</option>
                    }) }
                  </select>
                </div>
              </div>
              <div className={styles.selectAreaItem}>
                <label htmlFor="filter3">区分</label>
                <div className={`${styles.inputSelect} ${styles.division}`}>
                  <select name="filter3" value={this.state.filter3} id="filter3" onChange={this.handleChange}>
                    { data.filter.edges.filter((item) => item.node.type === filter1)[0].node.nodes[ (filter2) ? (filter2>1) ? 2 : 1 : 0 ].nodes.map((item, id ) => {
                      return <option key={id} value={item.filter3}>{item.name}</option>
                    }) }
                  </select>
                </div>
              </div>
            </div>
            <button className={`${buttonStyles.btn} ${buttonStyles.btnRound}`}>表示</button>
          </form>
        )}
      />
    )
  }
}
export default SearchFilter