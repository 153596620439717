import React from "react"
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"
import * as chartStyles from "../../styles/components/chart.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Modal from "react-modal"

import SearchFilter from "../../components/parts/chart/search-filter"
import ImageBox from "../../components/parts/image-box"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      s04_1_2_1 : false,
      s04_1_2_2 : false,
      s04_1_2_4 : false,
      s04_1_2_5 : false,
      s04_1_2_6 : false,
      s04_1_2_7 : false,
      s04_1_2_8 : false
    }

    this.searchInit = {
      filter1Init: 3,
      filter2Init: 0,
      filter3Init: 'technology-trend',
      current: props.location.pathname
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : true
    })
  }
  closeModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : false
    })
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading}>{title}</h1>
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>国別 ゼロエミッション船関連プロジェクト件数</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s04_1_2_1"}>
                <img src={'../../images/chart/s04-1-2-1.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s04_1_2_1}
                close={this.closeModal}
                name={"s04_1_2_1"}
                src={'/images/chart/s04-1-2-1.png'}
                alt="国別 ゼロエミッション船関連プロジェクト件数"
                width={899}
                height={470} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.globalmaritimeforum.org/publications/mapping-of-zero-emission-pilots-and-demonstration-projects" target="_blank" rel="noreferrer noopener">Getting to Zero Coalition, “Mapping of Zero Emission Pilots and Demonstration Projects“</a>（2022年3月）</p>
                <p className={chartStyles.chartExplain}>海運の脱炭素化に焦点をあてた、船舶技術、燃料製造、バンカリング、インフラに関するプロジェクトを対象としている。<br />
                複数国にまたがるプロジェクトも存在する。</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>地域別 ゼロエミッション船関連プロジェクト件数</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s04_1_2_2"}>
                <img src={'../../images/chart/s04-1-2-2.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s04_1_2_2}
                close={this.closeModal}
                name={"s04_1_2_2"}
                src={'/images/chart/s04-1-2-2.png'}
                alt="地域別 ゼロエミッション船関連プロジェクト件数"
                width={731}
                height={383} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.globalmaritimeforum.org/publications/mapping-of-zero-emission-pilots-and-demonstration-projects" target="_blank" rel="noreferrer noopener">Getting to Zero Coalition, “Mapping of Zero Emission Pilots and Demonstration Projects“</a>（2022年3月）</p>
                <p className={chartStyles.chartExplain}>海運の脱炭素化に焦点をあてた、船舶技術、燃料製造、バンカリング、インフラに関するプロジェクトを対象としている。<br />
                複数地域・分野にまたがるプロジェクトも存在する。</p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>燃料別 ゼロエミッション船関連プロジェクト件数</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s04_1_2_3"}>
                <img src={'../../images/chart/s04-1-2-3.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s04_1_2_3}
                close={this.closeModal}
                name={"s04_1_2_3"}
                src={'/images/chart/s04-1-2-3.png'}
                alt="燃料別 ゼロエミッション船関連プロジェクト件数"
                width={1022}
                height={831} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.globalmaritimeforum.org/publications/mapping-of-zero-emission-pilots-and-demonstration-projects" target="_blank" rel="noreferrer noopener">Getting to Zero Coalition, “Mapping of Zero Emission Pilots and Demonstration Projects“</a>（2022年3月）</p>
                <p className={chartStyles.chartExplain}>海運の脱炭素化に焦点をあてた、船舶技術、燃料製造、バンカリング、インフラに関するプロジェクトを対象としている。<br />
                Large：大型船舶<br />
                Small：小型船舶</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>船種別 ゼロエミッション船関連プロジェクト件数</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s04_1_2_4"}>
                <img src={'../../images/chart/s04-1-2-4.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s04_1_2_4}
                close={this.closeModal}
                name={"s04_1_2_4"}
                src={'/images/chart/s04-1-2-4.png'}
                alt="船種別 ゼロエミッション船関連プロジェクト件数"
                width={704}
                height={572} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.globalmaritimeforum.org/publications/mapping-of-zero-emission-pilots-and-demonstration-projects" target="_blank" rel="noreferrer noopener">Getting to Zero Coalition, “Mapping of Zero Emission Pilots and Demonstration Projects“</a>（2022年3月）</p>
                <p className={chartStyles.chartExplain}>海運の脱炭素化に焦点をあてた、船舶技術に関するプロジェクトを対象としている。</p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>ゼロエミッション船 船舶関連プロジェクト内訳</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s04_1_2_5"}>
                <img src={'../../images/chart/s04-1-2-5.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s04_1_2_5}
                close={this.closeModal}
                name={"s04_1_2_5"}
                src={'/images/chart/s04-1-2-5.png'}
                alt="ゼロエミッション船 船舶関連プロジェクト内訳"
                width={785}
                height={525} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.globalmaritimeforum.org/publications/mapping-of-zero-emission-pilots-and-demonstration-projects「" target="_blank" rel="noreferrer noopener">Getting to Zero Coalition, “Mapping of Zero Emission Pilots and Demonstration Projects“</a>（2022年3月）</p>
                <p className={chartStyles.chartExplain}>海運の脱炭素化に焦点をあてた、船舶技術に関するプロジェクトを対象としている。<br />
                Large：大型船舶<br />
                Small：小型船舶</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>ゼロエミッション船 燃料製造関連プロジェクト内訳</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s04_1_2_6"}>
                <img src={'../../images/chart/s04-1-2-6.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s04_1_2_6}
                close={this.closeModal}
                name={"s04_1_2_6"}
                src={'/images/chart/s04-1-2-6.png'}
                alt="ゼロエミッション船 燃料製造関連プロジェクト内訳"
                width={954}
                height={638} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.globalmaritimeforum.org/publications/mapping-of-zero-emission-pilots-and-demonstration-projects" target="_blank" rel="noreferrer noopener">Getting to Zero Coalition, “Mapping of Zero Emission Pilots and Demonstration Projects“</a>（2022年3月）</p>
                <p className={chartStyles.chartExplain}>海運の脱炭素化に焦点をあてた、燃料製造に関するプロジェクトを対象としている。</p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>ゼロエミッション船 バンカリング・インフラ関連プロジェクト内訳</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s04_1_2_7"}>
                <img src={'../../images/chart/s04-1-2-7.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s04_1_2_7}
                close={this.closeModal}
                name={"s04_1_2_7"}
                src={'/images/chart/s04-1-2-7.png'}
                alt="ゼロエミッション船 バンカリング・インフラ関連プロジェクト内訳"
                width={1200}
                height={515} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.globalmaritimeforum.org/publications/mapping-of-zero-emission-pilots-and-demonstration-projects" target="_blank" rel="noreferrer noopener">Getting to Zero Coalition, “Mapping of Zero Emission Pilots and Demonstration Projects“</a>（2022年3月）</p>
                <p className={chartStyles.chartExplain}>海運の脱炭素化に焦点をあてた、バンカリング、インフラに関するプロジェクトを対象としている。</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>船種・動力源別コスト(TCO)見通し（2030年）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s04_1_2_8"}>
                <img src={'../../images/chart/s04-1-2-8.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s04_1_2_8}
                close={this.closeModal}
                name={"s04_1_2_8"}
                src={'/images/chart/s04-1-2-8.png'}
                alt="船種・動力源別コスト(TCO)見通し（2030年）"
                width={929}
                height={400} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.iea.org/reports/energy-technology-perspectives-2020" target="_blank" rel="noreferrer noopener">IEA, "Energy Technology Perspectives 2020"</a> （2020年9月）</p>
                <p className={chartStyles.chartExplain}>船舶の耐用年数における総コストを総運航距離で除した値（TCO; Total cost of ownership）の2030年時点の見通しを船種別、動力源別に表示。<br />
                Tanker：タンカー<br />
                Container ship：コンテナ船<br />
                Bulk carrier：ばら積み貨物船 </p>
              </div>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`