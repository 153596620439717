import React from "react"
import Modal from "react-modal"
import * as styles from "../../styles/components/image-box.module.scss"

const ImageBox = (props) => (
    <Modal
    isOpen={props.open}
    onRequestClose={props.close}
    >
    <div>
      <button onClick={props.close} data-name={props.name}>
        <img src="/images/ico-close.svg" width="24" alt="x" />
      </button>
      <div className={styles.imageBox}>
        <img src={props.src} alt="" width={props.width} height={props.height} />
      </div>
    </div>
  </Modal>
)
export default ImageBox